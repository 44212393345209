
.Main-content-area {
    position: absolute;
    top: var(--navbar-height);
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
}

.Main-content-area > div:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2%;
}

@media screen and (min-width: 1024px) {
    
    .Main-content-area {
        left: var(--sidebar-width);
    }

    .Main-content-area > div:first-child {
        /*padding for cards / important for removing title from card / default is 5% all around*/
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10%;
    }
}