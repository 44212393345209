/*Display for mobile (under 1024px)*/
@media screen and (max-width: 1024px) {
    /*Removes Text*/
    .span-nav{
        display: none
    }

    .Sidebar {
        display: block;
        position: fixed;
        z-index: 1;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: white;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
    }

    .Sidebar .nav {
        display: inline-block;
        flex-direction: row;
        margin:1em;
    }

    .Nav-item {
        border-radius: 8px;
        padding:0.5em;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1em;
        margin-right: 1em;
        text-decoration: none;
        color: var(--primary) !important;
    }

    .Nav-item--active, .Nav-item:hover {
        background-color: var(--primary);
        color: white !important;
    }
}


/*Smaller Display for small screens*/
@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .span-nav{
        display: none;
    }
    .Sidebar {
        display: block;
        width: 6em;
        position: fixed;
        top: var(--navbar-height);
        bottom: 0;
        left: 0;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: left;
    }

    .Sidebar .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-top: 1.45rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .Nav-item {
        border-radius: 8px;
        margin-top: 1.25rem;
        padding: 0.8125rem 1.937rem 0.8125rem 1rem;
        text-decoration: none;
        color: var(--primary) !important;
    }

    .Nav-item--active, .Nav-item:hover {
        background-color: var(--primary);
        color: white !important;
    }
}

/*Full Display*/
@media screen and (min-width: 1300px) {
    
    .Sidebar {
        display: block;
        width: var(--sidebar-width);
        position: fixed;
        top: var(--navbar-height);
        bottom: 0;
        left: 0;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: left;
    }

    .Sidebar .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-top: 1.45rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .Nav-item {
        border-radius: 8px;
        margin-top: 1.25rem;
        padding: 0.8125rem 1.937rem 0.8125rem 1rem;
        text-decoration: none;
        color: var(--primary) !important;
    }

    .Nav-item--active, .Nav-item:hover {
        background-color: var(--primary);
        color: white !important;
    }


}