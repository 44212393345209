
.Welcome-message {
    text-align: left;
}

.Info-container {
    color: white;

}

.Info-container-1 {
    background-color: var(--primary);
}

.Info-container-2 {
    background-color: var(--scheme-col-2);
}

.Info-container-3 {
    background-color: var(--scheme-col-3);
}

.Info-container-4 {
    background-color: var(--scheme-col-4);
}