@import "react-slideshow-image/dist/styles.css";

.Complaint-image {
    max-width: 80%;
    margin: auto;
    left: 0;
    right: 0;
}
.react-slideshow-wrapper.slide {
    width: 50%;
    margin: auto;
} 
.each-slide {
    text-align: center;
}