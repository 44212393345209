a {
  text-decoration: none;
}
body {
  /* background: -webkit-linear-gradient(bottom, #2dbd6e, #a6f77b); 
  Not sure what this is used for, but it gives the background a weird 
  gradient if you decide to do padding bot on mobile view for navbar*/
  background-repeat: no-repeat;
}

label {
  text-align: left;
  font-size: 10pt;
}

/* forgot password */
#forgot-pass {
  color: var(--secondary);
  font-size: 10pt;
  margin-top: 3px;
  text-align: right;
}

/*Dont have account yet?*/
#signup {
  color: var(--secondary);
  font-size: 10pt;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 10px;
}

/*card itself*/
#card {
  background: white;
  border-radius: 20px;
  box-shadow: 1px 10px 18px rgba(0, 0, 0, 0.65);
  height: fit-content;
  margin: 6rem auto 8.1rem auto;
  width: 25em;
  
}
#card-content {
  padding: 30px 44px;
  
}
#card-title {
  padding-bottom: 23px;
  padding-top: 13px;
  text-align: center;
  
}

/* default / successful button */
#submit-btn {
    background: -webkit-linear-gradient(left, var(--primary), var(--success));
    border: none;
    border-radius: 21px;
    cursor: pointer;
    color: white;
    height: 42.3px;
    margin: 0 auto;
    margin-top: 50px;
    width: 153px;
    transition: all 0.5s;
}

/* >> Animation css*/
#submit-btn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

#submit-btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -30px;
    transition: 0.5s;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

#submit-btn:hover {
  background: -webkit-linear-gradient(300deg, var(--primary), var(--success)); 
  box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
    padding-right: 30px;
    cursor: pointer;
}

#submit-btn:hover span:after {
    opacity: 1;
    right: -15px;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(135deg, var(--primary), var(--success));
}

#submit-btn:focus{
    box-shadow: 0px 1px 40px 5px var(--danger);
    transition: 1s;
}


/* error button
    --currently not in use--
*/

/*
#submit-btn-wrong {
  background: -webkit-linear-gradient(right, var(--danger), var(--danger));
  border: none;
  border-radius: 21px;
  cursor: pointer;
  color: white;
  height: 42.3px;
  margin: 0 auto;
  margin-top: 50px;
  transition: 0.25s;
  width: 153px;
  animation: blinking 1s;
}
#submit-btn-wrong:hover {
  box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

@keyframes blinking {
    0% {
        box-shadow: 0px 5px 50px var(--danger);
    }
    100% {
        box-shadow: 0px 1px 18px WHITE;
    }
} */

.form {
  align-items: left;
  display: flex;
  flex-direction: column;
}

.form-border {
  background: -webkit-linear-gradient(right, var(--primary), var(--info));
  height: 1px;
  width: 100%;
}

.form-border2 {
  background: -webkit-linear-gradient(right, var(--primary), var(--info));
  height: 1px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}

.form-content {
  background: #fbfbfb;
  border: none;
  outline: none;
  padding:7px;
  border-radius: 10px;
}

.form-content2 {
  background: rgb(236, 236, 236);
  border: none;
  outline: none;
  padding:10px;
  padding-left: 15px;
  border-radius: 15px;
  width: 25em;
}


.space{
    visibility: hidden;
}
.error{
    color: var(--danger);
    font-size: 10pt;
    
}