/*General CSS*/
.Task-input {
    border: 1px solid var(--secondary);
    border-radius: 15px;
    padding:15px;
    height: auto;
    width: 25em;
    border-color: lightgray;

}

/*Added Tasks CSS*/
.Task-input > .Task-tag {
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(123,214,123,1) 35%, rgba(87,182,87,1) 100%);
    margin: 2px;
    width: 100%;
    padding-left: 15px;
    padding-top: 1px;
    padding-bottom: 1px;
    float: left;
    
}

.Task-input > .Task-tag:hover {
    background: linear-gradient(90deg, rgba(255,109,109,1) 35%, rgba(255,71,71,1) 100%);
    cursor: pointer;
    transition: 0.2s;
    color: white;
    
} 

.Task-input > .Task-tag span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.Task-input > .Task-tag span:after {
    content: '⛔';
    color: white;
    position: relative;
    opacity: 0;
    top: 5px;
    right: -60px;
    transition: 0.5s;
    float: right;
    pointer-events:none;
}

.Task-input > .Task-tag:hover span:after {
    opacity: 1;
    right: -20px;
}

/*Tast Input Field CSS*/
.Task-input > .Input-field {
    border: none;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(230, 230, 230);
    width: 100%;
    border-radius: 10px;
    margin-top:5px;
    margin-left:2px;
    
    
}


/*Date input field*/
.date_widget{
    border:none;
    border-radius: 10px;
    width: 100%;
    
}

/*Time input field*/
.time_widget{
    border:none;
    border-radius: 10px;
    width: auto;
    padding-left:10px;
}

/*Employee select widget*/
.employee_widget{
    width: 100%;
    border-radius: 10px;
    border: none;
}


/*The n-th of month and day of week picker*/

#picker1, #picker2{
    margin-left:5px;
    padding:5px;
    border-radius: 10px;
    border-color: lightgray;
}


/*Thing that makes them show up side by side*/
.wrapper {
    display:flex;
}


