
.Welcome-message {
    text-align: left;
}

.Info-container {
    color: white;

}

.space2{
    display: none;
}
    
/* Info Container 1 Animation*/
.Info-container-1 {
    background-image: linear-gradient(to right, #6190E8 0%, #A7BFE8  51%, #6190E8  100%);
    transition: 1s;
    background-size: 200% auto;  
    display: block;
}

.Info-container-1:hover{
   background-position: right center; /* change the direction of the gradient */
}


/* Info Container 2 Animation*/
.Info-container-2 {
    background-image: linear-gradient(to right, #D66D75 0%, #E29587  51%, #D66D75  100%);
    transition: 1s;
    background-size: 200% auto;  
    display: block;
}
.Info-container-2:hover{
    background-position: right center;
}


/* Info Container 3 Animation*/
.Info-container-3 {
    background-image: linear-gradient(to right, #3fc598 0%, #55ddc4  51%, #3fc598  100%);
    transition: 1s;
    background-size: 200% auto;  
    display: block;
}
.Info-container-3:hover{
    background-position: right center;
}


/* Info Container 4 Animation*/
.Info-container-4 {
    background-image: linear-gradient(to right, #F2994A 0%, #F2C94C  51%, #F2994A  100%);
    transition: 1s;
    background-size: 200% auto;  
    display: block;
}
.Info-container-4:hover{
    background-position: right center;
}

#ic_bground{
    background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
    background-size:  400% 400%;
    animation: gradient 10s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes gradient {
    0% {
      background-position: 0%;
    }
    50% {
      background-position: 100%;
    }
    100% {
      background-position: 0%;
    }
}

#dashboard-center{
    text-align: center;
}


.text_centered_img{
    font-size: xx-large;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    text-shadow: 4px 4px 10px #333333;
}
#lmao{
    font-size: xx-large;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    text-shadow: 4px 4px 10px #333333;
}
#lmao:before{
    content: '';
    animation: lmao infinite 120s;
}
@keyframes lmao{
    0%{content: 'Wow, that was awful';}
    10%{content: 'The dirtiest hands often clean the best';}
    20%{content: 'Do not mix up your brushes!';}
    30%{content: 'Imagine doing this for a living';}
    40%{content: 'One more day like this and im quitting';}
    50%{content: 'We need more black people in our company';}
    60%{content: 'I always vote for the ruling party';}
    70%{content: 'Im slacking so hard rn LMAO';}
    80%{content: 'Blacks are a menace to our society';}
    90%{content: 'Im not cleaning that...';}
    100%{content: 'If you think I am a diversity hire, you are right';}
}

/*Display for mobile (under 1024px)*/
@media screen and (max-width: 1024px) {
    .space2{
        display: block;
        visibility: hidden;
        
    }
}