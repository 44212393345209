:root {
  --black: #000000;
  --primary: #009fd4;
  --secondary: #a3a4a5;
  --success: #57B657;
  --info: #248AFD;
  --warning: #FFC100;
  --danger: #FF4747;
  --light: #f8f9fa;
  --dark: #282f3a;
  --sidebar-width: 15vw;
  --navbar-height: 5vh;
  --bgr-col: #F5F7FF;

  --scheme-col-2: #1B4965;
  --scheme-col-3: #F72585;
  --scheme-col-4: #EA7317;
}

@font-face {
  font-family: "Nunito-Regular";
  src: local('Nunito-Regular'), url(./fonts/Nunito-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: 'Nunito-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Nunito-Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Underline OLD
.underline {
  background: -webkit-linear-gradient(right, var(--primary), var(--success));
  height: 5px;
  width: 10em;
  border-radius: 5px;
} */
.underline{
  background-color: #08AEEA;
  background-image: linear-gradient(90deg, #08AEEA 0%,#d991fa 50%, #2AF598 100%);
  background-size:  400% 400%;
  animation: gradient 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  width: 10em;
  border-radius: 5px;
}

.underline_dashboard{
  background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
  background-size:  400% 400%;
  animation: gradient 10s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  width: 10em;
  border-radius: 5px;
}

.underline-title {
  background: #27a85b;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0575E6, #27a85b);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0575E6, #27a85b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size:  400% 400%;
  animation: gradient 10s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  width: 50%;
  border-radius: 5px;
  margin: 0rem auto 0 auto;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

code {
  font-family: 'Nunito-Regular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .btn-primary {
  background-color: var(--primary);
} */

.Panel-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Info-card {
  /* padding: 5%; */
  text-align: left;
  position: relative;
  border-radius: 10px;
  height: 100%;
  overflow-y: hidden;
  background-color: white;
  padding: 1rem;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.Info-card-bottom-nav {
  /* Add + nav page +/- on bottom of client, employee and other pages*/
  text-align: left;
  position: relative;
  border-radius: 10px;
  height: 50px;
  background: none;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  overflow: auto;
  
}

/*Changed Arrows for left / right */
.Page-counter {
  padding-right: 2em;
  float: right;
  position: absolute;
  right: 0;
}
/*clickable buttons themselves*/
.sidebutton {
  border-radius: 100px;
  margin-left: 1em;
  margin-right: 1em;
  border: none;
}

.Striped-table {
}

.Table {
  width: 100%;
  text-align: center;
}



.Table-page-count {
  padding:2em;
  float: right;
  position: absolute;
  bottom: 0;
  right: 0;
  /* border-color: ; */
}

.Striped-table tbody tr {
  /*border-top: 2px solid rgb(227, 227, 227);*/
  padding: 1rem;
  cursor: pointer;
}

.Striped-table tbody tr td, .Striped-table thead tr th {
  padding: 1.125rem;
  width: 30%;
}

.Striped-table tbody tr:nth-child(odd) {
  background-color: #F5F7FF;
}

/* FORM COMPONENTS */
.pw-form{
  padding: 0.875rem 1.375rem;
  width: 21em;
  height:2.8em;
  border-radius: 25px;
  border-width: 1px;
  border-color: var(--secondary);
}

.Form-control {
  padding: 0.875rem 1.375rem;
  border-radius: 25px;
  border-width: 2px;
  height:2.8em;
  border-color: var(--secondary);
  transition: border-color 0.15s ease-in-out, box-shadow 0.0 ease-in-out;
}

/* Buttons Found Across Different Pages*/

/*Confirmation Button*/
.confirmation_button{
  background: right, var(--primary);
  border: none;
  border-radius: 21px;
  cursor: pointer;
  color: white;
  height: 42.3px;
  margin: 0 auto;
  width: 153px;
  transition: all 0.5s;
}

.confirmation_button:hover {
  background: var(--success);
  cursor: pointer;
  transition: 1s;
}

/*Complaint Button*/
.complaint_button{
  background: right, var(--scheme-col-4);
  border: none;
  border-radius: 21px;
  cursor: pointer;
  color: white;
  height: 42.3px;
  margin:  auto;
  width: 250px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  text-align:center;
  padding-left: 3em;
}

.complaint_button:hover {
  background: var(--danger);
  cursor: pointer;
  transition: 1s;
}

/*Page + / - Buttons on client and emloyee lists*/
.plus-minus{
  background: right, var(--secondary);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  color: white;
  height: 2em;
  width: 2em;
  transition: all 0.5s;
  margin: 5px;
}

.plus-minus:hover {
  background: var(--primary);
  cursor: pointer;
  transition: 1s;
}

.accordion-header{
  border-radius: 15px !important;
  border: none !important;
  
}

.accordion-button{
  background-image: linear-gradient(to right, #ffffff 0, #ffffff  100%) !important;
  border-radius: 15px !important;
  border: none !important;
}

.accordion-button:not(.collapsed){
  color: #fff !important;
  background: var(--primary) !important;
  font-weight: bold;
  border-radius: 15px !important;
  border: none !important;
}

.accordion-item{
  border-radius: 15px !important;
}

