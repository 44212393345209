
.Top-N-Header {
    text-align: left;
}

.Top-N-Table {
    width: 100%;
}

.Top-N-Table tbody tr:nth-child(odd) {
    background-color: #F5F7FF;
}
  
.Top-N-Table tbody tr td, .Top-N-Table thead tr th {
  padding: 1.125rem;
  width: 33%;
}

.Clickable-tr {
    cursor: pointer;
}