
.GenericModal {
    z-index: 100;
    position: fixed;
    display: table;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.ModalMiddle {
    display: table-cell;
    vertical-align: middle;
    
}

.GenericModal > .ModalMiddle > .ModalContainer {
    background-color: white;
    padding: 3rem;
    padding-left: 5em;
    padding-right: 5em;
    border-radius: 20px;
    margin: auto;
    width: fit-content;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
    box-shadow: 1px 10px 18px rgba(0, 0, 0, 0.65);
    
}
/*Title*/
#modal-title{
    text-align: center;
}

#modal-title1{
    text-align: center;
    padding-left: 1.2em;
    padding-right: 1.2em;
    
}

/*Title Underline*/
.underline-title-modal {
    background: var(--primary);
    height: 5px;
    margin: 0rem auto 0 auto;
    width: 50%;
    border-radius: 5px;
}

/* Input field*/
.field {
    background: #fbfbfb;
    border: none;
    outline: none;
    padding-top: 14px;
    width: 100%;
}

/*Button Look + Animation*/
#modal_button {
    background: right, var(--primary);
    border: none;
    border-radius: 21px;
    cursor: pointer;
    color: white;
    height: 42.3px;
    margin: 0 auto;
    width: 153px;
    transition: all 0.5s;
}

#modal_button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

#modal_button span:after {
    content: '+';
    font-weight:700;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -30px;
    transition: 0.5s;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

#modal_button:hover {
    background: var(--success);
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
    padding-right: 30px;
    cursor: pointer;
}

#modal_button:hover span:after {
    opacity: 1;
    right: -20px;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

/*centering for buttons*/
#button_centering{
    text-align: center;
}

/*Complaint modal*/
#modal_button_complaint {
    background: right, var(--primary);
    border: none;
    border-radius: 21px;
    cursor: pointer;
    color: white;
    height: 42.3px;
    margin: 0 auto;
    width: 153px;
    transition: all 0.5s;
}

#modal_button_complaint span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#modal_button_complaint span:after {
    content: '⚠️';
    font-weight:700;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -30px;
    transition: 0.5s;
}

#modal_button_complaint:hover {
    background: var(--danger);
    padding-right: 30px;
    cursor: pointer;
}

#modal_button_complaint:hover span:after {
    opacity: 1;
    right: -30px;
}

/* Text_area and Comment text */
#text_area {
    width: 30em;
    border-radius: 15px;
    margin-bottom: 1em;
}
#comment {
    color:blue;
    padding: 0.7em;
    border-radius: 15px;
    border: none;
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: auto;
    margin-bottom: 1em;
}



.image-file{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Image file select ... not working properly atm*/
.file{
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.file-input label {
    display: block;
    position: relative;
    width: 150px;
    height: 25px;
    margin: auto;
    border-radius: 25px;
    background: linear-gradient(40deg, #ff6ec4, #7873f5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform .2s ease-out;
}

