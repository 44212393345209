.App {
  text-align: left;
  background-color: var(--bgr-col);
  text-align: center;
  overflow-x: hidden;
  position: relative;
}

.App > div {
  min-height: 100vh;
  min-width: 100vw;
}

::-webkit-scrollbar{
  width: 12px;
}

::-webkit-scrollbar-track{
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb{
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: var(--primary);;
  border-radius: 100px;
}

@media screen and (max-width: 1024px) {
  body{
    padding-bottom: 100px;
  }      
}

/*Za kartice koje prelaze okvire stranice*/
.scrollbarSolution{
  min-height: fit-content;
  max-height: 500px;
  overflow-y: auto;
  height: 100%;
}

/*Search Bar attempt to remove weird dual color selector - mozda je ovo samo issue na win? */

.Form-control1{
  margin-left: 5px;
  padding:10px;
  padding-left: 15px;
  border-radius: 25px;
  background-color: rgb(236, 236, 236);
  border-style: none !important;
  transition: 1s;
}

.Form-control1:focus{
  /* background-color: rgb(255, 255, 255); */
  /* transition: 1s; */
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/* DROPDOWN */
.Custom-dropdown {
  border-radius: 10px;
  color: #fff;
  background: var(--primary);
  border: none;
  padding: 2px 10px
}

/* TASK STYLE */

.Task-list {
  border-radius: 5px;
  margin: .5rem;
}
.Task-item {
  padding: 8px;
  font-size: 14px;
  background-color: #f6f4f4;
}
.Task-item.complete {
  border-left: 6px solid green;
  border-radius: 5px 0 0 5px;
}
.Task-item.notcomplete {
  border-left: 6px solid red;
  border-radius: 5px 0 0 5px;
}

/* FILE BTN */
.file-name {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
}
.error {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.error-title {
  color: #000;
  font-size: 20px;
}
.error-message {
  color: red;
  font-size: 14px;
}