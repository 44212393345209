/*Everything is inside of GenericModal.css*/
.add-btn {
    left: -25px;
    top: 10px;
    font-size: 25px;
    color: var(--primary);
    cursor: pointer;
}
.remove-btn {
    right: -20px;
    top: 8px;
    font-size: 22px;
    color: var(--danger);
    cursor: pointer;
}
