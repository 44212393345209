.Add-plus-btn {
    background: right, var(--primary);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    color: white;
    height: 2em !important;
    width: 4em;
    transition: all 0.5s;
    padding-left: 1.25em;
    padding-top: 0.18em;
    position: relative;
    margin-left: 1em;
}

.Add-plus-btn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    width: 4.7em;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

.Add-plus-btn span:after {
    content: 'Add';
    position: absolute;
    opacity: 0;
    top: 0;
    width: 5em;
    right: -30px;
    transition: 0.5s;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}

.Add-plus-btn:hover {
    background: var(--success);
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
    padding-right: 30px;
    width: 6em;
    cursor: pointer;
}

.Add-plus-btn:hover span:after {
    opacity: 1;
    right: -35px;
    box-shadow: 0px 1px 18px -webkit-linear-gradient(right, var(--primary), var(--success));
}