
.Navbar {
    position: fixed;
    height: var(--navbar-height);
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0px 5px 21px -5px #CDD1E1;

}
.Navbar > .Navbar-right {
    padding-right: 1.5rem;
}

/* Logo positioning on small screens */
/* Logo je premesten tako da je uz levu ivicu ekrana bez da je dodiruje.
Trebalo bi da je koliko-toliko u liniji sa elementima ispod njega */
.Navbar > .Logo-container, .Navbar > .Logo-container > img {
    max-height: 100%;
    float: left;
    padding-left: 0.6rem;
    padding-bottom: 0.1rem;

}

.Topnav-item {
    float: right;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.Topnav-profile-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary);
    color: white;
    border-radius: 100px;
    background-color: none;
}

.Topnav-profile-button:hover {
    cursor: pointer;
    border-radius: 16px;
    transition: all 0.3s;
}

/* Dropdown i animacije za njega */
.Topnav-dropdown-menu {
    position: absolute;
    top: calc(var(--navbar-height) + 10px);
    right: 1.5rem;
    width: fit-content !important;
    height: fit-content !important;
    box-shadow: 0px 5px 21px -5px #CDD1E1;
    color: white;
    background-color: #ff6868;
    transition: all 0.5s;
    overflow-x: hidden;
}

.Topnav-dropdown-menu span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.Topnav-dropdown-menu span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -30px;
    transition: 0.5s;
    
}

.Topnav-dropdown-menu:hover {
    background-color: var(--danger);
    padding-right: 30px;
    cursor: pointer;
}

.Topnav-dropdown-menu:hover span:after {
    opacity: 1;
    right: -15px;
}

/*Icon inside of the menu/profile button. "Log Out" in this case*/
.menu_icon {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 0.5rem;
}



@media screen and (min-width: 1024px) {
    /*Logo positioning on big screens*/
    .Navbar {
        max-height: 100%;
        float: left;
        padding-left: 0.6rem;
        padding-bottom: 0.1rem;
    }
    
    .Topnav-dropdown-menu {
    float: right;
    }
    
    /*Removal of mini logo on desktop*/
    #img_mini {
        display: none;
    }

    .Topnav-profile-button {
        height: 35px;
        width: 35px;
    }
}

@media screen and (max-width: 1024px) {
    /*Removal of full logo on mobile*/
    #img_normal {
        display: none;
    }

    .Topnav-profile-button {
        height: 35px;
        width: 35px;
    }
}