

.Day-section {
    border: none;
    padding-right: 1em;
}

.Assignee-info {
    text-align: left;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Asignee-info__email {
    font-size: 13px;
}
.Asignee-info__user {
    font-size: 20px;
}

